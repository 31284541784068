<template>
  <div class="vue-tempalte">
    <!-- Navigation -->
    <!-- <nav class="navbar shadow bg-white rounded justify-content-between flex-nowrap flex-row fixed-top "
      v-if="isNotLogin">
      <img src="./assets/logo.png" width="20" style="border-radius: 4px;margin-left: 14px;" />
      <div class="container">
        <div>
    
        </div>

        <ul class="nav navbar-nav flex-row float-right">
          <li class="nav-item">
            <router-link class="nav-link pr-3" to="/login">Sign in</router-link>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-outline-primary" to="/">Sign up</router-link>
          </li>
        </ul>
      </div>
    </nav> -->
    <!-- Main -->
    <div class="App">
      <vue-confirm-dialog></vue-confirm-dialog>

      <!-- <div class="vertical-center"> -->
      <div>
        <!-- <Sidebar v-if="isNotLogin" /> -->
        <Tabnav v-if="isTab" />
        <div :class="[!isNotLogin ? '' : 'inner-block']">

          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tabnav from './components/Tabnav.vue';
export default {
  components: { Tabnav },
  computed: {
    // a computed getter
    token: function () {
      // `this` points to the vm instance
      return localStorage.getItem('jwtToken')
    },
    isNotLogin: function () {
      // Compare the current route's name with the provided name
      console.log(this.$route.name)
      return this.$route.name != 'login';
    },
    isTab: function () {
      // Compare the current route's name with the provided name
      console.log(this.$route.name)
      return this.$route.name === 'home' || this.$route.name === 'history' || this.$route.name === 'party' || this.$route.name === 'wallet';
    },
  },
  methods: {

  }

};
</script>
<style>
.btn-primary,
.nav-link.active {
  background-color: #f11111 !important;
  border: 1px #f11111 solid !important;
  color: #ffffff !important;
}

a {
  color: #000000 !important;
}

.form-label {
  font-size: 12px !important;
}

.card-title {
  font-size: 14x !important;

}

.card-text {
  font-size: 12px !important;
  margin: 0
}

.btn {
  font-size: 12px !important;

}

.btn-sm {
  font-size: 10px !important;

}


.font-size-h1 {
  font-size: 32px !important;
}

.font-size-h2 {
  font-size: 24px !important;
}

.font-size-h3 {
  font-size: 18px !important;
}

.font-size-h4 {
  font-size: 14px !important;
}

.font-size-h5 {
  font-size: 12px !important;
}

.font-size-h6 {
  font-size: 9px !important;
}

.font-size-h7 {
  font-size: 6px !important;
}

.bg-sp-primary {
  background-color: #ff1111 !important;
  color: white !important
}

.bg-gray {
  background-color: #F2F3F6;
}

.bg-black2 {
  background-color: #5f5f5f !important;
  color: white !important
}

.bg-PENDING {
  background-color: #ffb900 !important;
  color: white !important
}

.bg-COMPLETED {
  background-color: #008121 !important;
  color: white !important
}

.bg-CANCELLED {
  background-color: #ff0000;
}

.text-pending {
  color: #ffb900
}

.text-completed {
  color: #008121
}

.text-checking {
  color: #5f5f5f
}

.text-cancelled {
  color: #ff0000
}

.card-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-block {
  cursor: pointer;
  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out !important;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(34, 35, 58, 0.15);

  &:hover {
    transform: translateY(-5px);
    box-shadow: none;
    opacity: 0.9;
    box-shadow: 0px 0px 6px rgba(34, 35, 58, 0.15);

  }
}

.card {
  border: transparent solid 0px !important;


}


.card-1 {}

.card-2 {
  height: 180px;

}

.card-3 {}

.text-eclipsis {
  /* text-align: center; */

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}


.search {
  width: 100%;
  height: 44px;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 24px;
  margin-right: 12px;
}

.search-input {
  color: #000;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  caret-color: transparent;
  line-height: 20px;
  transition: width 0.4s linear
}

.search .search-input {
  padding: 0 10px;
  width: 100%;
  caret-color: #f11111;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  transition: width 0.4s linear;
}


.search-icon {
  height: 44px;
  width: 50px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #f11111;
  font-size: 10px;
  border-radius: 12px;
}

.search-icon:hover {

  color: #fff !important;
}

a:link {
  text-decoration: none
}

.scrolling-wrapper {
  overflow-x: auto;
}

h1 {
  font-weight: 700;
  font-size: 3.4em;
}

.subtitle {
  font-size: 1.25em;
  opacity: 0.65;
}

.scrolling-wrapper-vertical {
  display: flex;
  flex-direction: column;
  /* แนวตั้ง */
  overflow-y: auto;
  /* เปิดใช้งานการเลื่อนแนวตั้ง */
  padding: 4px;
  border-radius: 4px;
  background-color: #f3f3f3;

  /* กำหนดความสูงโดยหักส่วนหัวออก */
  /* แทนที่ 60px ด้วยความสูงของ div ด้านบน */

}

.modal-input {
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: fit-content;
  height: fit-content;
  max-width: 22em;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: #FFF;
  z-index: 999;
  transform: none;
}


.modal-overlay {
  content: '';
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #000000;
  opacity: 0.3;
  cursor: pointer;
}

/* ---------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}

.card-shop-img-top {
  border-radius: 4px;
  width: 100%;
  max-height: 140px;
  object-fit: cover;
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1000px;
  padding: 10px 24px;
  z-index: 9999;
  left: 50%;
  transform: translateX(-50%);

  background-color: white;
}

.screen-login {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: #ffffff8f;
}

.screen {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: #ffffff;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* @media (min-width: 1054px) {
  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 214px;
    z-index: 9999;
    background-color: white;
  }


} */

/* ใช้ max-height เมื่อจอกว้างกว่า 441px */
@media (min-width: 441px) {
  .card-shop-img-top {
    height: auto;
    /* ยกเลิกความสูงคงที่ */
    max-height: 220px;
    /* จำกัดความสูงสูงสุดไม่ให้เกินกรอบ */
    object-fit: contain;
    /* ปรับให้รูปภาพไม่ถูก crop และยังคงอัตราส่วนเดิม */
  }


}
</style>