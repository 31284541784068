import router from "@/router";
import firebase from "firebase";

const state = {
  token: localStorage.getItem("token") || null,
  userData: JSON.parse(localStorage.getItem("userData")) || {},
  userActive: localStorage.getItem("userActive") || 'anonymous',
  balance: localStorage.getItem("balance") || 0,
  point: localStorage.getItem("point") || 0,
  financialData: JSON.parse(localStorage.getItem("financialData")) || {},
  rewards: JSON.parse(localStorage.getItem("rewards")) || [],
  transaction: JSON.parse(localStorage.getItem("transaction")) || [],
  history: JSON.parse(localStorage.getItem("history")) || [],

};

const getters = {
  getToken: (state) => {
    return state.token;
  },
  getUserData: (state) => {
    console.log(state.userData)
    return state.userData;
  },
  getUserActive: (state) => {
    console.log(state.userActive)
    return state.userActive;
  },
  getBalance: (state) => {
    return parseFloat(state.balance);
  },
  getPoint: (state) => {
    return parseFloat(state.point);
  },
  getFinancialData: (state) => {
    return state.financialData
  },
  getRewards: (state) => {
    return state.rewards
  },
  getTransaction: (state) => {
    return state.transaction
  },
  getHistory: (state) => {
    return state.history
  }
};

const actions = {
  setToken({ commit }, token) {
    return new Promise((resolve) => {
      let header_token = `${token}`;
      commit("SET_TOKEN", header_token);
      resolve(header_token);
    });
  },
  setPoint({ commit }, point) {
    return new Promise((resolve) => {
      commit("UPDATE_POINT", point);
      resolve(point);
    });
  },
  setBalance({ commit }, balance) {
    return new Promise((resolve) => {
      commit("UPDATE_BALANCE", balance);
      resolve(balance);
    });
  },
  setUserData({ commit }, userData) {
    return new Promise((resolve) => {
      commit("UPDATE_USER_DATA", userData);
      resolve(userData);
    });
  },
  setFinancialData({ commit }, financialData) {
    return new Promise((resolve) => {
      commit("UPDATE_FINANCIAL_DATA", financialData);
      resolve(financialData);
    });
  },
  setRewards({ commit }, rewards) {
    return new Promise((resolve) => {
      commit("UPDATE_REWARDS", rewards);
      resolve(rewards);
    });
  },
  setTransaction({ commit }, transaction) {
    return new Promise((resolve) => {
      commit("UPDATE_TRANSACTION", transaction);
      resolve(transaction);
    });
  },
  setHistory({ commit }, history) {
    return new Promise((resolve) => {
      commit("UPDATE_HISTORY", history);
      resolve(history);
    });
  },
  setUserActive({ commit }, userActive) {
    return new Promise((resolve) => {
      commit("UPDATE_USER_ACTIVE", userActive);
      resolve(userActive);
    });
  },

  setDisplayName({ commit }, name) {
    return new Promise((resolve) => {
      commit("UPDATE_USER_INFO", { displayName: name });
      resolve(name);
    });
  },
  // checkAuth({ commit, getters }) {
  //   return new Promise((resolve, reject) => {
  //     let header_token = getters.getToken;
  //     if (!header_token) {
  //       return reject({ success: false });
  //     }
  //     axios
  //       .get(`${process.env.VUE_APP_BACKEND_API_URL}/auth/info`, {
  //         headers: { Authorization: header_token },
  //       })
  //       .then(({ data }) => {
  //         if (data.success) {
  //           commit("UPDATE_USER_INFO", { displayName: data.username });
  //         } else {
  //           commit("DEL_TOKEN");
  //           reject(data);
  //           return router.push("/logout");
  //         }
  //         return resolve(data);
  //       })
  //       .catch((err) => {
  //         commit("DEL_TOKEN");
  //         reject(err);
  //         return router.push("/logout");
  //       });
  //   });
  // },
  async logout({ commit }) {
    try {
      // Sign out from Firebase
      await firebase.auth().signOut();

      // Clear local storage and token
      localStorage.clear();
      commit("DEL_TOKEN");

      // Avoid redundant navigation
      if (router.currentRoute.path !== "/login") {
        await router.replace("/login"); // Use replace to prevent adding to history
      }
    } catch (error) {
      // Ensure proper navigation in error case
      commit("DEL_TOKEN");

      if (router.currentRoute.path !== "/login") {
        await router.replace("/login");
      }

      throw error;
    }
  }
}
const mutations = {

  UPDATE_USER_DATA(state, userData) {
    localStorage.setItem("userData", JSON.stringify(userData));
    state.userData = userData;
  },
  UPDATE_FINANCIAL_DATA(state, financialData) {
    localStorage.setItem("financialData", JSON.stringify(financialData));
    state.financialData = financialData;
  },
  UPDATE_REWARDS(state, rewards) {
    localStorage.setItem("rewards", JSON.stringify(rewards));
    state.rewards = rewards;
  },
  UPDATE_TRANSACTION(state, transaction) {
    localStorage.setItem("transaction", JSON.stringify(transaction));
    state.transaction = transaction;
  },
  UPDATE_HISTORY(state, history) {
    localStorage.setItem("history", JSON.stringify(history));
    state.history = history;
  },
  UPDATE_USER_ACTIVE(state, userActive) {
    localStorage.setItem("userActive", userActive);
    state.userActive = userActive;
  },
  UPDATE_BALANCE(state, balance) {
    localStorage.setItem("balance", balance);
    state.balance = balance;
  },
  UPDATE_POINT(state, point) {
    localStorage.setItem("point", point);
    state.point = point;
  },
  SET_TOKEN(state, token) {
    localStorage.setItem("token", token);
    state.token = token;
  },
  DEL_TOKEN(state) {
    localStorage.removeItem("token");
    state.token = null;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
