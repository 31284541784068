import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "../store/store";
/* eslint-disable */
Vue.use(VueRouter);

let routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginScreen.vue'),
    meta: {
      requiresAuth: false, // Public route
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutScreen.vue'),
    meta: {
      requiresAuth: false, // Public route
    },
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/ProductScreen.vue'),
    meta: {
      requiresAuth: false, // Public route
    },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('../views/WalletScreen.vue'),
    meta: {
      requiresAuth: false, // Public route
    },
  },
  {
    path: '/party',
    name: 'party',
    component: () => import('../views/PartyScreen.vue'),
    meta: {
      requiresAuth: false, // Public route
    },
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/HistoryScreen.vue'),
    meta: {
      requiresAuth: false, // Public route
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterScreen.vue'),
    meta: {
      requiresAuth: false, // Public route
    },
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/ShopScreen.vue'),
    meta: {
      requiresAuth: true, // Protected route
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeScreen.vue'),
    meta: {
      requiresAuth: true, // Protected route
    },
  },
  {
    path: '/',
    name: 'start',
    component: () => import('../views/StartScreen.vue'),
    meta: {
      requiresAuth: true, // Protected route
    },
  },
  {
    path: '/reward',
    name: 'reward',
    component: () => import('../views/RewardScreen.vue'),
    meta: {
      requiresAuth: true, // Protected route
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/PaymentScreen.vue'),
    meta: {
      requiresAuth: true, // Protected route
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactScreen.vue'),
    meta: {
      requiresAuth: true, // Protected route
    },
  },
];

// Adding layout property from each route to the meta object
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "history", // Use history mode to enable clean URLs
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.getToken;
  const getUserActive = store.getters.getUserActive;
  if (to.name === 'login' && isAuthenticated) {
    // หากเข้าสู่ระบบแล้ว และพยายามเข้าหน้า login ให้เปลี่ยนเส้นทางไปยังหน้า home
    next('/');
  } else if (to.name !== 'start' && to.name !== 'login' && to.name !== 'logout' && to.name !== 'contact' && !from.name && isAuthenticated && getUserActive != 'anonymous') {
    // หากไม่ได้มาจาก start และยังไม่ได้ไป start ให้เปลี่ยนเส้นทางไป start
    next('/');
  } else if (to.name === 'register' && !isAuthenticated) {
    // หากไม่ได้มาจาก start และยังไม่ได้ไป start ให้เปลี่ยนเส้นทางไป start
    next('/login');
  }
  else if (to.name === 'register' && getUserActive === 'true') {
    // หากไม่ได้มาจาก start และยังไม่ได้ไป start ให้เปลี่ยนเส้นทางไป start
    next('/');
  }
  else {
    // อนุญาตให้เข้าถึงหน้า
    next();
  }
});
export default router;
