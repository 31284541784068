import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/app';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';

import '@/assets/css/main.css'
import axios from "./axios.js";
import store from "./store/store";
import VueConfirmDialog from 'vue-confirm-dialog'


Vue.use(VueConfirmDialog)
Vue.config.productionTip = false

Vue.prototype.$http = axios;
Vue.prototype.$storex = store;
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
const firebaseConfig = {
  apiKey: "AIzaSyCWGT6ideESatHbtXhM8yb2zWPq9idlAcU",
  authDomain: "shoppong-data.firebaseapp.com",
  projectId: "shoppong-data",
  storageBucket: "shoppong-data.appspot.com",
  messagingSenderId: "56556148387",
  appId: "1:56556148387:web:48405b9aab86d5bffad116",
  measurementId: "G-FDYT0WQFF7"
};
firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV === 'production') {
  console.log = function () { }; // ปิดการทำงานของ console.log
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')