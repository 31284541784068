<template>
    <nav class="nav">
        <router-link to="/home" class="nav__link">
            <i class="bi-house-door" :class="{ active: isActive('home') }" style="font-size: 14px;"></i>

        </router-link>
        <router-link to="/history" class="nav__link">
            <i class="bi-handbag" :class="{ active: isActive('history') }" style="font-size: 14px;"></i>

        </router-link>
        <router-link to="/party" class="nav__link">
            <svg class="my-icon" :class="{ active: isActive('party') }" width="22px" height="22px"
                style="margin-bottom: 4px;" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_iconCarrier">
                    <path
                        d="M5.5713 14.5L9.46583 18.4141M18.9996 3.60975C17.4044 3.59505 16.6658 4.33233 16.4236 5.07743C16.2103 5.73354 16.4052 7.07735 15.896 8.0727C15.4091 9.02443 14.1204 9.5617 12.6571 9.60697M20 7.6104L20.01 7.61049M19 15.96L19.01 15.9601M7.00001 3.94926L7.01001 3.94936M19 11.1094C17.5 11.1094 16.5 11.6094 15.5949 12.5447M10.2377 7.18796C11 6.10991 11.5 5.10991 11.0082 3.52734M3.53577 20.4645L7.0713 9.85791L14.1424 16.929L3.53577 20.4645Z"
                        class="icon-path"></path>
                </g>
            </svg>

        </router-link>
        <router-link to="/wallet" class="nav__link">
            <div v-if="showRewardsCount && rewardsCount > 0">
                <div class="d-flex justify-content-center align-items-center bg-sp-primary"
                    style="width: 16px;height: 16px;align-items: center;border-radius: 50px; position: absolute;top:10px;">
                    <div class="font-size-h6">{{ rewardsCount }}</div>
                </div>
            </div>
            <i class="bi-wallet2" :class="{ active: isActive('wallet') }" style="font-size: 14px;"></i>

        </router-link>

    </nav>

</template>

<script>

export default {
    name: 'SidebarMenu',
    async created() {
        const userActive = this.$storex.getters.getUserActive
        if (userActive != "anonymous") {
            this.rewardsCount = this.$storex.getters.getRewards.length
        }
        this.updateShowRewardsCount(); // Set initial state based on current route

    },
    data() {
        return {
            rewardsCount: 0,
            showRewardsCount: true,
        };
    },
    watch: {
        $route: "updateShowRewardsCount", // Watch for route changes
    },
    methods: {
        isActive(name) {
            // Compare the current route's name with the provided name
            return this.$route.name === name;
        },
        updateShowRewardsCount() {
            // Set `showRewardsCount` based on the current route
            this.showRewardsCount = this.$route.name !== "wallet";
            this.rewardsCount = this.$storex.getters.getRewards.length
        },
    },
};
</script>
<style>
.nav {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
}

.my-icon .icon-path {
    stroke: #a4a4a4;
    fill: white;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    color: #a4a4a4 !important;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

.nav__link:hover {
    background-color: #eeeeee;
}

.active {
    color: #000;
}

.my-icon.active .icon-path {
    stroke: #000;
    /* สีเมื่อ active */
}

.nav__icon {
    font-size: 18px;
}
</style>